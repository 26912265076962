import React from 'react';
import { Layout } from '../../../components/Layout';

const LeaseAreaSurveys = () => (
  <Layout
    title="Utility Right of Way Surveys in Calgary and Area | Legal Survey Services"
    description="Ensure compliance with property regulations. Axiom Geomatics offers professional utility right of way surveys for Calgary projects. Contact us today!"
    keywords="lease area surveys, boma, building owner and managers association, boma standards, lease area calculations"
  >
    <main>
      <h1>Lease Area Surveys Calgary & Area</h1>
      <p>
        A <strong>Lease Area Survey</strong> usually uses the BOMA standard method of determining
        the square footage of the lease area. The lease area typically allows a party, other than
        the owner of the property, to have limited rights to the property.
      </p>

      <p>Common examples of lease area surveys include:</p>

      <ul>
        <li>Leasing an area within a building for a business</li>
        <li>Specifying the area for signage, advertising</li>
        <li>Allocating rights for communication towers (e.g. cell phone towers)</li>
      </ul>
    </main>
  </Layout>
);

export default LeaseAreaSurveys;
